.container {
    margin-top: 20px;
}
.table-container {
    margin-top: 20px;
}
.button-container {
    margin-top: 20px;
}
.my-table {
    padding: 5px;
}

body {
    background-color: #f8f9fc;
}