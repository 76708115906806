.container {
    margin-top: 20px;
}
.paper {
    padding: 10px;
}
.custom-input-grid {
    padding:10px;
}
.custom-input {
    min-width: 200px !important;
}
.buttonContainer {
    margin-top: 20px;
}
.input-container {
    padding: 10px;
}

body {
    background-color: #f8f9fc;
}